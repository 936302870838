@charset 'UTF-8';

// 0. Reset
@import "reset";

// 1. Abstracts
@import "./abstracts/vars";

// 2. Vendors
@import "~bootstrap/scss/bootstrap";

// 3. Base
@import "./base/base", "./base/helpers";

// 4. Layout
@import "./layout/header", "./layout/main", "./layout/footer";

// 5. Components
@import "./components/modal", "./components/home", "./components/results",
  "./components/product-selector", "./components/simulator",
  "./components/spinner", "./components/results", "./components/results-dosage",
  "./components/formulation-screen", "./components/legal-notice";

// 6. Pages
@import "./pages/verif";

// 7. Themes

// 8. Shame
@import "shame";

// 9. Fonts
@font-face {
  font-family: "Klavika";
  src: url("fonts/klavika-light.otf") format("woff"),
    url("fonts/klavika-light.otf") format("opentype"),
    url("fonts/klavika-light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Klavika";
  src: url("fonts/klavika-medium.otf") format("woff"),
    url("fonts/klavika-medium.otf") format("opentype"),
    url("fonts/klavika-medium.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Klavika";
  src: url("fonts/klavika-bold.otf") format("woff"),
    url("fonts/klavika-bold.otf") format("opentype"),
    url("fonts/klavika-bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
