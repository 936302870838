.logo {
  height: 120px;
}

.authentification__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 40px;
}

.authentification__form__item {
  margin: 40px 0;
}

.authentification__form__item input {
  width: 100%;
  padding: 10px;
  font-size: 1.3rem;
  text-align: center;
  border: none;
  border-bottom: 4px solid #000;
}

.authentification__form__item input.isFilled {
  border-bottom: 4px solid #ffc510;
}

.error-message {
  margin-top: 10px;
  color: red;
  font-weight: normal;
  font-size: 0.8em;
}

.authentification__form__item.btn input {
  background-color: #eba31b;
  color: #fff;
  border: none;

  transition: all 0.3s ease-in;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  font-weight: 700;
}

.authentification__form__item.btn:hover {
  background-color: #fff;
  color: #eba31b;
}

@media (min-width: $breakpoint-screen) {
  .authentification__form {
    align-items: center;
  }

  .authentification__form__item, .authentification__form .btn {
    width: 80%;
  }
}
