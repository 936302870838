.formulation-screen {
  &__title {
    font-weight: bold;
    font-size: $font-size-x-large;
  }

  &__description {
    font-size: $font-size;
  }

  & .btn {
    width: 100%;
    padding: $font-size;
    font-weight: 600;
    margin-top: unset;

    &.btn--secondary {
      margin-bottom: 1em;
    }
  }
}

.carbon-footprint__intro {
  margin-bottom: 26px;
}

.product-data-block {
  width: 100%;
  padding: 32px 0;
  border-top: 1px solid #E1E1E1;

  &:first-child {
    border-top: none;
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 20px;
    font-weight: bold;

    span {
      &.separator {
        border-bottom: 1px dotted black;
        content: "";
        flex: 1;
      }
    }

    &__content {
      margin: 0 16px;
    }

    &__delete {
      background: none;
      border: none;
      color: $red;
    }
  }

  & label {
    font-weight: 600;
    margin-bottom: 12px;
  }

  & .form-group__select-container {
    border-radius: unset;
    margin: 0 0.5em 0 0.5em;
    width: 95%;

    & select {
      padding: 12px;
    }
  }

  & .form-control-lg {
    border-radius: unset;
  }

  .form-label {
    display: flex;
    justify-content: space-between;

    .form-group__delete-button {
      background: transparent;
      border: none;
      color: #9E9E9E;
      font-weight: 600;
      font-size: 14px;

      img {
        margin-right: 10px;
        margin-bottom: 2px;
      }
    }
  }
}

.main--carbon-footprint {
  display: flex;
  flex-direction: column;

  .result-screen {
    flex: 1;
    display: flex;
    flex-direction: column;

    .result__item {
      margin-bottom: 26px;
    }

    .formulation-result,
    .carbon-gain-block p {
      font-size: 40px;
      font-weight: 400;
      color: $yellow;
      text-transform: uppercase;
    }

    .button-block {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
}

@media (min-width: $breakpoint-screen) {
  .main--carbon-footprint {
    .result-screen {
      .button-block {
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0;
        margin-top: auto;

        button {
          width: 300px;
          height: 120px;
          background-color: $yellow;
        }
      }
    }
  }

  .formulation-screen {
    flex: 1;
    display: flex;
    flex-direction: column;

    .simulator__form {
      flex: 1;
      flex-direction: column;
      align-items: center;

      form {
        flex: 1;
      }

      .btn {
        width: 300px;
        height: 120px;

        &.simulator__form__validate {
          margin-top: auto;
        }
      }
    }
  }
}
