.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  background-color: $header-background;
  color: $black;
  border-bottom: 1px solid $light-grey;

  &__brand-logo {
    position: absolute;
    right: $brand-logo-margin;
    top: $brand-logo-top;
    width: $brand-logo-width;
  }

  &__logo {
    height: auto;
    margin-top: $logo-margin-top;
    max-width: 200px;
  }

  &__nav {
    margin-bottom: unset;

    &__item {
      margin-top: -6px;
      cursor: pointer;
      border: 1px solid $white;
      color: $white;
    }

    padding-left: unset;
  }

  &.header--carbon-footprint {
    & .header__brand-logo {
      left: calc(100vw / 2 - 25px);
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .header {
    height: $header-height-on-tablet-and-above;

    &__brand-logo {
      top: $brand-logo-top-on-tablet-and-above;
      width: $brand-logo-width-on-tablet-and-above;
    }

    &__logo {
      height: $logo-height-on-tablet-and-above;
      margin-top: $logo-margin-top-on-tablet-and-above;
      max-width: auto;
    }
  }
}

.mix-and-cast .header {
  @media (min-width: $breakpoint-tablet) {
    &__logo {
      height: $logo-height-on-tablet-and-above-mix-and-cast;
      margin-top: $logo-margin-top-on-tablet-and-above-mix-and-cast;
      max-width: unset;
    }
  }
}

.header.header--with-partner {
  height: $header-with-partner-height;
  display: flex;
  justify-content: center;

  .header__logo {
    height: auto;
    margin-top: unset;
    margin-bottom: unset;
  }

  .header__brand-logo.partner-logo {
    top: calc($logo-height + 10px);
  }
}
