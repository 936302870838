.display-on-mobile {
  display: block;
}

.display-on-tablet-and-above {
  display: none;
}

@media (min-width: $breakpoint-tablet) {
  .display-on-mobile {
    display: none;
  }

  .display-on-tablet-and-above {
    display: block;
  }
}
