// COLORS
$white: #ffffff;
$black: #000000;
$yellow: #ffc510;
$red: #ff3939;

$light-grey: #d8d8d8;

$nature-dark: #260b01;
$nature-medium: #73564c;
$nature-light: #d9beb4;

// COLOR USAGE
$header-background: $yellow;
$footer-background: $white;
$button-background: $yellow;
$button-secondary-background: $white;
$button-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
$button-shadow-hover: 0 3px 6px rgba(0, 0, 0, 0.23);
$text-color-primary: $black;
$text-color-secondary: $white;

// SELECT
$select-border: #ced4da;
$select-focus: blue;
$select-arrow: $select-border;

// FONT
$font-size-small: 13px;
$font-size: 16px;
$font-size-large: 20px;
$font-size-x-large: 24px;

// BREAKPOINTS
$breakpoint-tablet: 768px;
$breakpoint-screen: 994px;
$breakpoint-large-screen: 1180px;

// SPECIFICS SIZE ON MOBILE
$app-padding: 0;
$header-height: 60px;
$header-with-partner-height: 90px;
$logo-height: 50px;
$footer-height: 40px;
$main-height: calc(100vh - #{$header-height} - #{$footer-height});
$home-padding: 2em;
$home-padding-top: 3em;
$content-height: calc(#{$main-height} - #{$home-padding} - #{$home-padding});
$logo-margin-top: calc((#{$header-height} - #{$logo-height}) / 2);
$brand-logo-width: $logo-height;
$brand-logo-margin: 10px;
$brand-logo-top: calc(
  #{$header-height} - #{$brand-logo-width} - #{$brand-logo-margin} / 2
);

//  SPECIFICS SIZE ON TABLET AND ABOVE
$header-height-on-tablet-and-above: 80px;
$logo-height-on-tablet-and-above: 70px;
$logo-height-on-tablet-and-above-mix-and-cast: 60px;
$main-height-on-tablet-and-above: calc(
  100vh - #{$header-height-on-tablet-and-above} - #{$footer-height}
);
$logo-margin-top-on-tablet-and-above: calc(
  (#{$header-height-on-tablet-and-above} - #{$logo-height-on-tablet-and-above}) /
    2
);
$logo-margin-top-on-tablet-and-above-mix-and-cast: calc(
  (
      #{$header-height-on-tablet-and-above} - #{$logo-height-on-tablet-and-above-mix-and-cast}
    ) / 2
);
$brand-logo-width-on-tablet-and-above: $logo-height-on-tablet-and-above;
$brand-logo-top-on-tablet-and-above: calc(
  #{$header-height-on-tablet-and-above} - #{$brand-logo-width-on-tablet-and-above} -
    #{$brand-logo-margin} / 2
);
