.footer {
	height: $footer-height;
	background-color: $footer-background;

	&__links {
		display: flex;
		justify-content: space-around;
		align-content: center;
		margin-bottom: unset;
		text-align: center;

		&__item {
			font-size: $font-size;
			font-weight: 700;
			text-align: center;
			line-height: 40px;
			vertical-align: middle;

			a {
				color: $black;
			}
		}
	}
}