.simulator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    margin-bottom: 30px;
  }

  &__form {
    width: 100%;
  }
}

// Bootstrap override
.form-group {
  // .formfield-select
  position: relative;

  &__select-container {
    //formfield-select--container
    position: relative;

    background-color: $white;
    border: $select-border 1px solid;
    border-bottom: 4px solid $black;
    border-radius: 10px;
    margin: 0 0 1.5em 0;

    overflow: hidden;
    /* 
			Le select natif pourra 
			dépasser sans être vu 
		*/

    &.isFilled {
      border-bottom: 4px solid $yellow;
    }

    & select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      width: 100%;
      /* 
				On est sûr de ne plus voir
				la flèche native 
			*/

      height: auto;
      border: 0;
      margin: 0;
      padding: 0.75em;
      border-radius: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      /* 
				On empêche le texte d'aller
				jusqu'au bout s'il est trop long
			*/
    }

    &::after {
      /* Le pointeur du select */
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -3px;
      right: 0.75em;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-top-color: $select-arrow;
      border-width: 6px;
      border-style: solid;
      pointer-events: none;
    }
  }

  & input[name="alcalin"] {
    border-bottom: 4px solid $black;

    &.isFilled {
      border-bottom: 4px solid $yellow;
    }

    &.hasError {
      border-bottom: 4px solid $red;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .simulator__form.complete-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;

    & .form-group {
      width: 45%;
      margin: 10px auto 10px auto;

      & .form-group__select-container {
        margin: unset;
        margin: 0 0.5em 0 0.5em;
        width: 95%;
    
      }

      & .form-control-lg {
        height: 67px;
        border-radius: 10px;

        & .invalid-feedback {
          position: absolute;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-screen) {
  .simulator__form.complete-form {
    & .form-group {
      width: 30%;
    }
  }
}

@media (min-width: $breakpoint-large-screen) {
  .simulator {
    max-width: 1300px;
    margin: 0 auto;
  }
}
