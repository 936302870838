.modal {
	position: absolute;
	display: block;
	z-index: 100;
	display: flex;
	justify-content: center;
	height: 100vh;
	min-height: 100vh;
	background-color: $light-grey;
	flex-direction: column;

	&__image {
		width: 75%
	}

	&__logo {
		margin-bottom: 2em;
	}

	&__close {
		width: 100%;
	}

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		padding: 10%;
		background-position: center;
		background-size: cover;
		font-weight: bold;
		text-align: center;
		overflow: auto;

		&--specifics {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			min-height: 25%;

			.specifics {
				&__text {
					margin-bottom: 30px;
					font-size: $font-size-large;
					text-align: center;
				}

				&__image {
					width: 75%;
				}
			}
		}

		&--uninstall {
			height: 25vh;
		}
	}
}

@media (min-width: $breakpoint-tablet) {
	.modal {
		&__content {
			padding: 75px;
			font-size: $font-size-large;

			&--specifics {
				.specifics__text {
					font-size: $font-size-x-large;
				}
			}
		}

		&__close {
			width: 300px;
		}
	}
}