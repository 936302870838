.main {
  margin: 0 auto;
  padding: $home-padding;
  padding-top: $home-padding-top;
  text-align: center;
  min-height: $main-height;
  background-size: cover;
  background-position: center;
}

.main {
  &.main--carbon-footprint {
    text-align: unset;
  }
}
