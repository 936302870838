.results__dosage {
	& h3 span {
		font-size: 0.7em;
	} 

	& .results-dose__item {
		margin: 10px;
		border: 2px solid $white;
		box-shadow: 5px 5px 8px $light-grey;
		padding: 10px;

		&-name {
			display: inline-block;
			text-transform: uppercase;
			margin-right: 20px;
			font-weight: bold;
		}

		&-dose {
			display: inline-block;
			font-size: $font-size-large;
		}
	}
}