.product-selector {
	padding: 20px 0;
	min-height: $content-height;
	background-color: rgba(255, 255, 255, 0.5) !important;

	& hr {
		background-color: $white;
		height: 4px;
	}

	& .results__dosage {
		margin-top: 30px;

		&.is-white {
			color: white;
			text-shadow: 1px 1px 6px black;
		}
	}
}

@media (min-width: $breakpoint-tablet) {
	.product-selector__content {
		width: 75%;
		margin: 0 auto;
	}
}

@media (min-width: $breakpoint-screen) {
	.product-selector {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		&__content {
			display: flex;
			justify-content: space-around;
			align-items: center;
			width: 90%;

			& hr {
				display: none;
			}
		}
	}
}

.form-group.cement-portion{
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 300px;
	margin: 0 auto;

	& .form-control {
		width: 75px;
		text-align: center;
	}

	& .invalid-feedback {
		position: absolute;
		top: 50px;
		font-size: $font-size;
		font-weight: bold;
	}
}