.simulator-results {
	display: flex;
	flex-direction: column;
	padding: 20px 0;
	min-height: $main-height;

	& hr {
		background-color: $white;
		height: 4px;
	}
	
	.results {
		&__issues {
			font-size: $font-size-small;

			span {
				cursor: pointer;

				a {
					color: $text-color-primary;
					font-weight: bold;
				}
			}
		}

		&__user-choices {
			text-align: center;

			& h4 {
				text-align: center;
			}

			& .btn {
				margin-top: unset;
				margin-bottom: 0.5em;
			}
	
			p {
				font-size: 0.9em;
				margin: 10px 0;
				border: 2px solid $white;
				box-shadow: 5px 5px 8px $light-grey;
				width: 100%;
				padding: 10px;
	
				strong {
					font-size: 1.4em;
				}
			}
		}
	}
}

@media (min-width: $breakpoint-tablet) {
	.simulator-results {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& hr {
			display: none;
		}
	
		.results {
			display: flex;
			justify-content: space-around;

			&__user-choices {
				text-align: left;
			}
		}
	}
}

@media (min-width: $breakpoint-screen) {
	.simulator-results {
		max-width: 900px;
		margin: 0 auto;
	}
}