.home {
  display: flex;
  width: 95%;
  min-height: $content-height;
  margin: 0 auto;
}

.home__cta {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 auto;

  &.home__cta--carbon-footprint {
    justify-content: unset;
    width: 100%;
  }
}

.launcher-app {
  &__content {
    padding: 48px 32px 32px 32px;

    nav {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .home {
    width: 90%;
  }

  .home__cta {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__item {
      width: 200px;
      height: 120px;
      margin-top: 0;
    }

    &.home__cta--carbon-footprint {
      justify-content: space-evenly;
      align-items: unset;

      & .home__cta__item {
        width: 300px;
      }
    }
  }
}

@media (min-width: $breakpoint-screen) {
  .home__cta {
    &__item {
      width: 250px;
    }
  }

  .launcher-app {
    &__content {
      h1 {
        text-align: center;
      }

      nav {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: unset;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 300px;
          cursor: pointer;
          min-height: 120px;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-large-screen) {
  .home {
    width: 80%;
  }

  .home__cta {
    &__item {
      width: 300px;
    }
  }
}
