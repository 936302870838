body {
  font-family: "Klavika", sans-serif;
}

.app {
  position: relative;
  padding: $app-padding;
  color: $text-color-primary;
}

a {
  color: $white;

  &:hover {
    color: $text-color-primary;
  }
}

p {
  margin-bottom: unset;
}

.btn {
  margin-top: 2em;
  border: none;
  box-shadow: $button-shadow;
  color: $black;
  background-color: $button-background;

  transition: all 0.3s ease-in;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  font-weight: 700;

  &:hover,
  &:focus,
  &:active,
  &:hover:active {
    box-shadow: $button-shadow-hover;
    color: $black;
    background-color: $button-background;
    transform: scale(1.025);
  }

  &.btn--secondary {
    background-color: $button-secondary-background;
    box-shadow: unset;
  }

  &.btn--add {
    span {
      margin-right: 5px;
      font-size: $font-size-large;
    }
  }
}

header {
  position: relative;
}

#burger-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  z-index: 10;
  display: flex;
  align-items: center;

  button {
    border: none;
    background: transparent;
    padding: 0;

    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background-color: black;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: all 0.5s ease-out;

      &:nth-child(1) {
        transform-origin: 0% 0%;
      }

      &:nth-child(3) {
        transform-origin: 0% 100%;
      }
    }
  }

  ul {
    background-color: $yellow;
    position: absolute;
    width: 200px;
    top: 59px;
    left: -210px;
    height: calc(100vh - 100%);
    margin: 0;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease-out;
    overflow-y: auto;

    li {
      margin: 8px 0;
      text-align: center;

      &.menu__bottom-item {
        margin-top: auto;
      }
    }

    a {
      color: black;
      text-decoration: none;
      letter-spacing: 0.03125rem;
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  &.open {
    ul {
      transform: translateX(200px);
    }

    button {
      border: none;
      background: transparent;

      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(-3px, -4px);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(1px, 0px);
        }
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  #burger-menu {
    ul {
      top: 79px;
    }
  }
}
